import React from "react";
import { BaseLayout } from "components";

class Home extends React.PureComponent {
  render() {
    return <BaseLayout>hello word</BaseLayout>;
  }
}

export default Home;
