import React from "react";
import { getNewsDetail } from "services";
import { BaseLayout, Loading } from "components";
import styles from "./styles.module.scss";
import { isNative, nativeHref } from 'utils';

class GuideDetail extends React.PureComponent {

  componentDidMount() {
    document.title = "取款须知";
  }


  // 返回
  onBack = () => {
    window.history.back();
  }



  render() {
    return (
      <BaseLayout>
        <section className={styles.detail_new}>

          {
            isNative ? null :
              <header className={styles.header}>
                <div className={styles.back} onClick={this.onBack} />
                <div className={styles.title}>取款须知</div>
              </header>
          }
          <div className={styles.content}>
            <p>1.为避免延误取款，确认取款指示前，请核对银行账户资料是否正确，如有疑问请联系客服。取款金额会存入您登记的银行账户中，取款货币与您登记的银行账户货币相同。</p>
            <p>2.出金请填写美元取款金额，取款金额需≥50美元，且保留两位小数。取款业务, 人民币与美元间兑换, 参考本公司提供的市场汇率（本公司参考中国银行（中国）公布的汇率而定），您可以在今日汇率查看当前汇率。</p>
            <p>3.出金对2种情况收取手续费：</p>
            <p>
            <p> i）如客户注资后交易量不足注资金额的60%,及最后一次注资金额并未用于投资交易，将扣取提款金额的6%作为取款手续费。</p>
            <p> ii）完成交易量后，24小时内最多可免费取款3次，若于24小时内第4次取款，则每次取款需扣取取款金额的6%作为手续费用。</p>
            <p> 4.为避免保证金低于50%(周末和假期为200%)系统的强制平仓给您带来的损失，出金请注意交易账户的持仓情况，因客户申请出金被强制平仓造成的损失由客户自行负责。</p>
            <p>5.出金两小时到账，如银行系统维护延迟取款办理，具体到账时间取决于银行处理速度。请勿使用非本人银行账户取款，否则我司保留作出对此处理的权利；详细请参阅客户协议书 。</p>
            </p>
          </div>
        </section>
      </BaseLayout>
    );
  }
}
export default GuideDetail;
